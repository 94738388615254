// 处理数据，调用不同的socket
import seriSocket from "./seriSocket2";
import bestSocket from "./bestSocket";
import generalSocket from "./generalSocket";
import escalator from "./escalator";
import stepSocket from "./stepSocket";
var elevators = []; // 原数据
var get_newElevator = null;
export default {
  set(data, callback, flag) {
    // 接收需要处理的数据
    this.clearSocket();
    get_newElevator = callback;
    Array.isArray(data)
      ? (elevators = JSON.parse(JSON.stringify(data)))
      : elevators.push(data);
    elevators.map((item, index) => {
      item.index = index;
    });
    this.setElevators(flag);
  },
  sendMessage(data) {
    seriSocket.sendMessage(data);
  },
  setElevators(flag) {
    for (let i = 1; i < 6; i++) {
      let eleArray = elevators.filter(
        (obj) => obj.websocketType == i * 10 || obj.moduleType == i * 10
      );
      if (eleArray.length > 0) {
        let func = (data) => {
          get_newElevator(data);
        };
        if (i === 1) {
          // 汇川 10
          seriSocket.sendSock(eleArray, func, flag);
        } else if (i === 2) {
          // 贝思特 20
          bestSocket.sendSock(eleArray, func, flag);
        } else if (i === 3) {
          // 通用 西班牙 30
          eleArray.map((item) => {
            generalSocket.sendSock(item, func, flag);
          });
        } else if (i === 4) {
          // 扶梯 40
          escalator.sendSock(eleArray, func, flag);
        } else if (i === 5) {
          // 新时达 50
          eleArray.map((item) => {
            stepSocket.sendSock(item, func, flag);
          });
        }
      }
    }
  },
  clearSocket() {
    seriSocket.closeSocket();
    bestSocket.closeSocket();
    generalSocket.closeSocket();
    escalator.closeSocket();
  },
};
