<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.terminalState')"
    id="terminalState"
    :class="{ mobileCss: !isMobile }"
  >
    <div id="outer-title">{{ $t("i18n.terminalState") }}</div>
    <el-form :inline="true" :model="eleData" class="demo-form-inline">
      <el-form-item :label="$t('i18n.elevatorNo') + '：'">
        <span class="blueColor">{{ eleData.number }}</span>
      </el-form-item>
      <el-form-item :label="$t('i18n.projectName') + '：'">
        <span class="blueColor">{{ eleData.projectName }}</span>
      </el-form-item>
      <el-form-item :label="$t('i18n.controllerType') + '：'">
        <span class="blueColor">{{ eleData.controllerType }}</span>
      </el-form-item>
    </el-form>
    <div class="flex">
      <div class="right">
        <div id="table-list" v-for="(item, i) in tableArr" :key="i">
          <h4>{{ item }}</h4>
          <div class="list" v-for="row in tableData[item]">
            <el-table :data="row" border style="width: 100%">
                <el-table-column
                  width="120"
                  align="center"
                  prop="name"
                  label="名称"
                ></el-table-column>
              <el-table-column
                width="100"
                v-if="i != 0 && i != tableArr.length - 1"
                align="center"
                label="端子有效"
              >
                <template #default="scope">
                  <div v-if="scope.row.valid == '有效'" style="color: red">
                    {{ scope.row.valid }}
                  </div>
                  <div v-else>{{ scope.row.valid }}</div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="值">
                <template #default="scope">
                  <div :style="{ color: scope.row.color }">
                    <span v-if="i != 0 && i != 3">
                      {{ scope.row.escapeValue }}:
                    </span>
                    {{ scope.row.value }}
                    <span v-if="scope.row.unit">({{ scope.row.unit }})</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import socket from "../../../public/js/socket/index2";
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "TerminalState",
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const state = reactive({
      eleData: {},
      isMobile: route.query.mobile || false,
      validData: {},
      tableArr: [],
      tableData: {},
    });
    emit("public_header", !state.isMobile);
    const init = async () => {
      let id = route.query.id;
      let { data } = await proxy.$api.system.getElevatorDetail(id);
      state.eleData = data;
      socket.set(
        state.eleData,
        (ele) => {
          fillF5Data(ele[0]);
          fillTerminal(ele[1]);
        },
        "F5"
      );
    };

    init();

    const fillF5Data = (data) => {
      if (!Array.isArray(data) || data.length == 0) {
        return;
      }
      if (state.validData.length > 0) {
        // console.log(data);
        var arr = data.filter((row) => row.name.indexOf("功能选择") > -1);
        // var input = state.validData.filter(
        //   (row) => row.functionCode.indexOf("输入端子") > -1
        // );
        // var output = state.validData.filter(
        //   (row) => row.functionCode.indexOf("输出端子") > -1
        // );
        arr.map((item) => {
          item.valid = item.value;
          if (item.value) {
            item.color =
              item.value.search(/\.常开/g) != -1
                ? "green"
                : item.value.search(/\.常闭/g) != -1
                ? "blue"
                : "";
          }
        });
        var arrX = arr.filter((row) => row.name.indexOf("X") > -1);
        var arrY = arr.filter((row) => row.name.indexOf("Y") > -1);
        arrX.map((item) => {
          // var valid = input.filter((row) => row.name == item.escapeValue);
          // item.valid = valid.length > 0 ? valid[0].value : "";
          item.valid = item.value.search(/常开/g) != -1
                ? "无效"
                : item.value.search(/常闭/g) != -1
                ? "有效"
                : item.value.search(/无效/g) != -1
                ? "无效"
                : "";
        });
        arrY.map((item) => {
          // var valid = output.filter((row) => row.name == item.escapeValue);
          item.valid = item.value.search(/常开/g) != -1
                ? "无效"
                : item.value.search(/常闭/g) != -1
                ? "有效"
                : item.value.search(/无效/g) != -1
                ? "无效"
                : "";
        });
        state.tableData["输入端子状态"] = groupFunc(arrX);  
        state.tableData["输出端子状态"] = groupFunc(arrY);
      }
    };

    const fillTerminal = (data) => {
      if (!Array.isArray(data) || data.length == 0) {
        return;
      }
      state.tableArr = [
        "电流/电压",
        "输入端子状态",
        "输出端子状态",
        "轿顶输入状态",
        "轿顶输出状态",
      ];
      state.tableArr.forEach((item) => {
        if (item.indexOf("端子状态") == -1) {
          var arr = data.filter((row) => row.functionCode.indexOf(item) > -1);
          if (item.indexOf("轿顶输入状态") > -1) {
            arr.map((row) => {
              row.valid = row.value;
              // var splitFCode = row.functionCode.split(":")[2].split("-");
              // if (splitFCode.length == 3) {
              //   // var functionCode = splitFCode[0] + "-" + splitFCode[1];
              //   var bit = splitFCode[2];
              // }
              // var bits = prefixInteger(row.escapeValue.toString(2), 4)
              //   .split("")
              //   .reverse(); //变成数组，反转数组元素
              // var v = bits[bit * 1];
              // var v = 0;
              // row.value = v == 0 ? v + ": 常开" : "1: 常闭";
              row.color = row.value == "有效" ? "green" : "blue";
            });
          }
          state.tableData[item] = groupFunc(arr);
        } else {
          // console.log(data);
          state.validData = data.filter(
            (row) =>
              row.functionCode.indexOf("输入端子状态") > -1 ||
              row.functionCode.indexOf("输出端子状态") > -1
          );
        }
      });
    };

    const groupFunc = (array) => {
      let newArray = [];
      for (var i = 0; i < 3; i++) {
        let size = Math.ceil(array.length / 3);
        if (array.slice(size * i, size * (i + 1)).length > 0) {
          newArray.push(array.slice(size * i, size * (i + 1)));
        }
      }
      return newArray;
    };

    // const prefixInteger = (num, length) => {
    //   //slice 从已有的数组返回选定的元素
    //   return (Array(length).join("0") + num).slice(-length);
    // };

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss">
#terminalState {
  .blueColor {
    color: $blue_color;
    margin-right: 20px;
  }
  .right {
    width: 100%;
    #table-list {
      .list {
        display: inline-block;
        width: 33%;
        vertical-align: top;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  #terminalState {
    margin: 0;
    padding: 10px;
    h4 {
      margin: 10px 0;
    }
    #table-list {
      .list {
        width: 100% !important;
        margin: 10px 0;
        .el-table {
          border-right: 1px solid #ebeef5;
        }
      }
    }
  }
  #terminalState.mobileCss {
    margin: 0 5px;
  }
}
</style>
